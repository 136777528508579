/**
 * Name: index.js
 * Description: Routes handler.
 * Created by: Jana Schumann on 2019-11-05
 * Last edited by: Jana Schumann on 2023-07-20
 * Last edited by: Lokeswari Madhusudhana on 2025-01-21
 */

import { createRouter, createWebHistory } from "vue-router";
import { i18n } from "@/main.js";
import Awards from "@/views/Awards";
import Background from "@/views/Background";
import Certificates from "@/views/Certificates";
import CitizenScience from "@/views/CitizenScience";
import Contact from "@/views/Contact";
import Data from "@/views/Data";
import Error from "@/views/404";
import Home from "@/views/Home";
import HowItWorks from "@/views/HowItWorks";
import Legal from "@/views/Legal";
import Papers from "@/views/Papers";
import Partnerships from "@/views/Partnerships";
import Physicians from "@/views/Physicians";
import Press from "@/views/Press";
import PrivacyPolicyTrials from "@/views/PrivacyPolicyTrials";
import Prolific from "@/views/Prolific";
import Researchers from "@/views/Researchers";
import RouterView from "@/views/RouterView";
import Scientists from "@/views/Scientists";
import Studies from "@/views/Studies";
import Team from "@/views/Team";
import ValidationStrategy from "@/views/ValidationStrategy";

export default createRouter({
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          // test y-offset, it was copied from another project
          resolve({
            selector: to.hash,
            // 96 - height of the header
            offset: { left: 0, top: 96 }
          })
        }, 500)
      })
    } else if (savedPosition || to.name === from.name) {
      // to.name === from.name - checks that we are still on the same page
      // (only parameters (:/lang) have been changed -> in this case we don't
      // need to scroll up)
      return savedPosition
    } else {
      return { left: 0, top: 0 }
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/:lang",
      component: RouterView,
      beforeEnter(to, from, next) {
        const lang = to.params.lang;
        if (!i18n.global.availableLocales.includes(lang)) return next(i18n.global.fallbackLocale);
        if (i18n.global.locale !== lang) {
          i18n.global.locale = lang;
        }
        return next();
      },
      children: [
        {
          path: "",
          name: "Home",
          component: Home
        },
        {
          path: "awards",
          name: "Awards",
          component: Awards
        },
        {
          path: "background",
          name: "Background",
          component: Background
        },
        {
          path: "certificates",
          name: "Certificates",
          component: Certificates
        },
        {
          path: "cs",
          name: "CitizenScience",
          component: CitizenScience
        },
        {
          path: "contact",
          name: "Contact",
          component: Contact
        },
        {
          path: "data",
          name: "Data",
          component: Data
        },
        {
          path: "error",
          name: "Error",
          component: Error
        },
        {
          path: "legal",
          name: "Legal",
          component: Legal
        },
        {
          path: "how",
          name: "HowItWorks",
          component: HowItWorks
        },
        {
          path: "papers",
          name: "Papers",
          component: Papers
        },
        {
          path: "partnerships",
          name: "Partnerships",
          component: Partnerships
        },
        {
          path: "physicians",
          name: "Physicians",
          component: Physicians
        },
        {
          path: "press",
          name: "Press",
          component: Press
        },
        {
          path: "privacypolicytrials",
          name: "PrivacyPolicyTrials",
          component: PrivacyPolicyTrials
        },
        {
          path: "prolific",
          name: "Prolific",
          component: Prolific
        },
        {
          path: "researchers",
          name: "Researchers",
          component: Researchers
        },
        {
          path: "scientists",
          name: "Scientists",
          component: Scientists
        },
        {
          path: "studies",
          name: "Studies",
          component: Studies
        },
        {
          path: "team",
          name: "Team",
          component: Team
        },
        {
          path: "validation",
          name: "ValidationStrategy",
          component: ValidationStrategy
        },
        //handles wildcards
        {
          path: "/:lang/:pathMatch(.*)*",
          redirect: "error"
        }
      ]
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/en"
    }
  ]
});