<!--
 Name: Prolific.vue
 Description: View to display the (hidden) prolific page.
 Created by: Jana Schumann on 2023-07-11
 Last edited by: Lokeswari Madhusudhana on 2025-03-19
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- hero graphic -->
    <hero-component
      title="Welcome to the 'Remember me' study"
      text="Here you will find step-by-step instructions and information about the study procedure and how to download and log in to the neotivTrials app."
      :image="require('@/assets/images/home/header-brain.svg')"
      :ratio="1"
      :heroColor="'#57509C'"
      :moreBtnHidden="true"
    >
    </hero-component>

    <!-- Only render the news banner if it's within the allowed week -->
    <!-- <template v-if="isWithinAllowedWeek">
      <news-banner-prolific />
    </template> -->

    <!-- course of the 'Remember me' study -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          Course of the 'Remember me' study
          <v-divider></v-divider>
        </h2>
        <p>
          Over a course of 9 weeks, there will be one memory test each week.
          We have 3 different test types that will each be performed 3 times during the entire study.
          In addition, several questionnaires are included.
        </p>
        <!-- study design -->
        <v-img
          :src="prolificStudyDesign"
          max-width="100%"
        />
        <br>
        <p><b>Study content</b></p>
        <ul class="ml-12 my-2">
          <li>The memory tests will each take about <b>10 to 20 minutes.</b> You will do <b>one test per week</b>, each consisting of two test phases, over a total time span of <b>9 weeks</b>.</li>
          <li>The additionally included questionnaires will each take about <b>5 to 15 minutes.</b> Questionnaires will be done at the beginning of the study, after the first 3 tests and at the very end.</li>
        </ul>
        <p>Further explanations for questionnaire content as well as how to perform the tests will be provided within the neotivTrials app.</p>
        <br>
        <p class="mb-2"><b>Payment</b></p>
        <p>You will get paid for each weekly study unit you perform.</p>
        <ul class="ml-12 my-2">
          <li><b>After each weekly test</b>, you will receive a <b>unique completion code</b> in the app.</li>
          <li>This completion code can then be <b>entered on the Prolific page</b> to receive payment.</li>
          <li>You will get invited to all following study units (2-9) in separate studies on Prolific after the first test, that is study unit, has been performed – you will enter a new completion code every week in the respective Prolific study invitation to guarantee your compensation.</li>
          <li>We pay <b>9£ / ~$11.37 per hour</b> plus a <b>one-time bonus of 9£ / ~$11.37 per hour</b> if you complete the whole study.</li>
        </ul>
        <p>For the <b>entire study</b> participation, you can earn <b>39,75£ / ~$50,22</b>!</p>
        <br>
        <p class="mb-2"><b>Exclusion criteria</b></p>
        <p>
          Please note that persons with one or more of the following conditions are not eligible for participation:
        </p>
        <ul class="ml-12 my-2">
          <li>Existing diagnosis with mild cognitive impairment or dementia</li>
          <li>Current or previous diagnosis with a mental health/illness/condition e.g. depression or anxiety</li>
          <li>Current or previous injury to the head that's caused you to be unconscious for a period of time</li>
        </ul>
      </v-container>
    </section>
    <!-- Start your participation -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          Start your participation
          <v-divider></v-divider>
        </h2>
        <!-- How does it work? -->
        <h3 class="text-left my-6">How does it work?</h3>
        <p>In general, the study participation works as follows:</p>
        <br>
        <p class="mb-2"><b>Phase 1:</b><br>If you do not have the neotivTrials app installed on your mobile device anymore: <b>Download</b> the neotivTrials app and log into the study. We provide you with <b>step-by-step instructions</b> in the section below.</p>
        <p class="mb-2"><b>Phase 2:</b><br><b>Follow the schedule and complete one test per week.</b>
          The neotivTrials app guides you step by step through the study and explains each memory test and questionnaire. After each test you will receive your unique completion code. Submit the completion code on the Prolific website and get paid!
        </p>
        <p><b>Phase 3:</b><br><b>Participate</b> until the end of the study and <b>get that bonus</b> payment!</p>
      </v-container>
    </section>

    <v-container>
      <!-- Step-by-step instructions -->
      <h3 class="text-left my-6">Step-by-step instructions</h3>
      <v-expansion-panels>
        <!-- Get your access code and password -->
        <v-expansion-panel
          title="1. Get your access code and password for the neotivTrials app"
        >
        <v-expansion-panel-text>
          <v-row no-gutters>
            <v-col cols="12" class="py-6">
              <p class="mb-6">This is different compared to the baseline assessment!</p>
              <p class="mb-6">On the Prolific platform, you will be provided with an <b>Access Code and a password when starting the study.</b> You will need these credentials to log into the neotivTrials app.</p>
              <p class="mb-6">The Access Code (called “Username” on Prolific) consists of 3 small letters and a number.</p>
              <p class="mb-6">Your password is a combination of numbers and letters. </p>
              <p class="mb-6">Please note down these credentials as you will need them to log into the app. We will not be able to restore those credentials. </p>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Installing neotivTrials app -->
        <v-expansion-panel
          title="2. Install the neotivTrials app"
        >
        <v-expansion-panel-text>
          <v-row no-gutters>
            <v-col cols="12" class="py-6">
              <p class="mb-6"><b>Step 1: Check technical requirements</b><br>You can use the neotivTrials app on a tablet or smartphone that uses at least <b>iOS version 13</b> or <b>Android version 9</b>. The version number is in the settings of your mobile device.</p>
              <p class="mb-6"><b>Step 2: Check your internet access</b><br>Your mobile device needs a stable internet connection via WIFI or mobile data.</p>
              <p class="mb-6"><b>Step 3: Install the neotivTrials app</b><br>The neotivTrials app can be downloaded in your app store. Please follow the link for the <a href="https://apps.apple.com/app/neotivtrials/id1611173081" target="_blank">App Store (iOS)</a> or the <a href="https://play.google.com/store/apps/details?id=com.neotiv.app.trials" target="_blank">Google Play (Android)</a>. You can also scan the QR code below to get to your respective app store.</p>
            </v-col>
          </v-row>
          <v-row class="mt-0" no-gutters>
            <v-col cols="4" class="py-6">
              <v-img
                class="ml-2"
                :src="appleLogo"
                max-width="180"
                cover
              />
              <v-img
                :src="qrTrialsApple"
                max-width="200"
                cover
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="8" class="py-6">
              <v-img
                class="ml-2"
                :src="androidLogo"
                max-width="180"
                cover
              />
              <v-img
                :src="qrTrialsGoogle"
                max-width="200"
                cover
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Accessing the study -->
        <v-expansion-panel
          title="3. Enroll for the study in the neotivTrials app"
        >
        <v-expansion-panel-text>
          <v-row no-gutters>
            <v-col cols="12" class="py-6">
              <p class="mb-6"><b>Step 1:</b><br>Click through the initial information in the app until you get to the <b>"What do you want to use the app for?" screen.</b></p>
              <p class="mb-6"><b>Step 2:</b><br>Choose item "Studies (restricted access)".</p>
              <v-img
                :src="studiesRestrictedAcess"
                max-width="200"
                cover
              />
              <p class="my-6"><b>Step 3:</b><br>Use your access code and password <b>provided on the Prolific website</b> to log into the study.</p>
              <v-img
                :src="login"
                max-width="200"
                cover
              />
            </v-col>
          </v-row>
        </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Data privacy -->
        <v-expansion-panel
          title="4. Data privacy"
        >
        <v-expansion-panel-text>
          <v-row no-gutters>
            <v-col cols="12" class="py-6">
              <p class="mb-6">Your information will remain safe as we operate under the <b>EU General Data Protection Regulation (GDPR)</b>. The data will be processed by neotiv as well as the collaborating research institute in Magdeburg, Germany (IKND - Institute of Cognitive Neurology and Dementia Research).</p>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
        </v-expansion-panel>

        <!-- Support Information -->
        <v-expansion-panel>
        <v-expansion-panel-title disable-icon-rotate>
          <span>5. Information: Time restrictions for customer support</span>
          <template v-slot:actions>
            <v-icon color="error" icon="mdi-alert-circle">
            </v-icon>
          </template>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row no-gutters>
            <v-col cols="12">
              <br>
              Customer support will be available Monday to Friday during the <b>German times between 8 am and 5 pm.</b> We assist you as soon as we can!
              <br>
            </v-col>
          </v-row>
        </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>

     <!-- Submissions on Prolific -->
     <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          Completing neotiv test and the submission on Prolific
          <v-divider></v-divider>
        </h2>
        <br>
        <v-expansion-panels>
          <v-expansion-panel
            title="Day 1 / Study unit 1"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-3">On the first day, follow the registration and installation process explained above to download and access the neotivTrials app. Complete the first questionnaires and memory test in the app. Then, enter the provided completion code from the app on the Prolific study invitation.</p>
                <p class="mb-3">Note: If you time out in the Prolific study, it's not a problem. We will still register your contribution and pay for your valid data.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            title="Study unit 2 until the end"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="text-decoration-underline mb-3">Starting from the second study unit, procedure is slightly different:</p>
                <p class="mb-3">You will be invited to the next study unit(s) on the Prolific website.</p>
                <b>Please start the respective study on Prolific AFTER you have performed the matching test unit in the neotivTrials app.</b>
                <p class="mb-3">Enter the new completion code provided in the app directly after you finish this study unit in the app.</p>
                <b>If the test in the app is not yet available, please wait until it becomes available and do not start the respective Prolific study yet.</b>
                <p class="mb-3">As soon as you have time to start and complete the entire test (part 1 and 2), afterward start the Prolific study for the matching test unit. Enter the completion code on Prolific immediately.</p>
                <b>Your study spot on Prolific is reserved and cannot be taken by anyone else meaning the study cannot run out.</b>
                <p class="mb-3">You will receive invitations for multiple study units after the study unit 2 and need keep them open in your Prolific Study Overview until you can perform the test in the neotivTrials app and enter the completion code.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </section>

    <!-- The fine print -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left my-6">
          The fine print
          <v-divider></v-divider>
        </h2>
        <p>This study processes health-related and demographic data as well as test data.  Before starting the study, <b>you will be asked within the neotivTrials app to provide your informed consent.</b> Therefore, we would like to inform you about the details of the study aim and data processing. <b>Please read the information in the drop-down menu below.</b></p>
        <br>
        <v-expansion-panels>
          <v-expansion-panel
            title="Benefits and risks"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <b>Aim of the “Remember me” study</b>
                <p class="mb-3">The aim of the study is to collect data about the performance in three different memory tests. In addition, the usability and feasibility of the tests are assessed in different questionnaires.</p>
                <b>Who can participate in the study?</b>
                <p class="mb-3">Registered Prolific users between 45 and 85 years of age can participate in the study. We are looking for participants who do not have any previous neurological diseases.</p>
                <b>Benefits and risks</b>
                <p class="mb-3">The studies are not associated with any risks.
                  By cooperating, you would make a decisive contribution to answering our study questions. You will not gain any direct personal benefit from participating in this study.
                </p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            title="Your rights"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <b>Your rights</b>
                <p>You can withdraw from participation at any time without giving any reason and without any disadvantages. To do so, please send a message via the <a @click="scrollToContact">‘Contact for Support’</a> on the bottom of this Website. You will of course be compensated for each study contributions made. You have the right to refuse to answer any questions. All your questions about the study will be answered as long as this does not affect the results of the study. If there are remaining questions, please send a message via the <a @click="scrollToContact">‘Contact for Support’</a> on the bottom of this Website.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            title="Data handling"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <b>What happens to my data?</b>
                <p class="mb-3">As part of the study, personal information will be collected digitally. The data will be stored and analyzed anonymously. The data is secured against unauthorized access and is stored after the end of the study.
                  Data will be processed by neotiv and the collaborating research institute in Magdeburg, Germany (IKND - Institute of Cognitive Neurology and Dementia Research; website: <a href="https://www.med.uni-magdeburg.de/iknd/en/">https://www.med.uni-magdeburg.de/iknd/en/</a>).
                </p>
                <b>Dealing with test results</b>
                <p>You cannot expect immediate test results for yourself. No medical diagnoses are made. The results can be published in a scientific journal and are used to optimize the neotiv memory tests. </p>
                <b>Expense allowance</b>
                <p>Your participation in this study is voluntary. There will be financial compensation of 9£ per hour plus a one-time bonus of 9£ if you complete the whole study until the end.

                  Consent will be asked to be given within the neotivTrials app. Refusing these terms as well as executing the right to revoke the consent will result in inability to participate in the “Remember me” study of neotiv through Prolific.
                </p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>

        </v-expansion-panels>
      </v-container>
    </section>

    <!-- Frequently Asked Questions -->
    <section id="all" class="bg-white">
      <v-container>
        <h2 class="text-left mb-6">
          Frequently Asked Questions (FAQs)
          <v-divider></v-divider>
        </h2>
        <v-expansion-panels>
          <v-expansion-panel
            title="What if I am unable to log into the neotivTrials app using the access code and password? The error message 'Sorry, login is not possible' appears in the app."
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-3">The access code needs to be entered in small letters.</p>
                <p class="mb-3">In some cases, a blank space after the access code hinders a successful login. Also, the self-set password could be entered incorrectly.</p>
                <p class="mb-3">You can try to close and restart the app - This error message occurs with incorrect credentials so maybe there might still be a typo in either of the fields.</p>
                <p class="mb-3">In case this does not work, please send a message via the "Contact for Support" on the bottom of this Website.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="How does the weekly data submission and payment work?"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-3">Once you have started the study within the neotivTrials app, the app will guide you through the entire study over the 9 weeks and remind you via push-notifications on your device about the next upcoming test and questionnaire (=study unit).</p>
                <p class="mb-3">For each weekly study unit, you will be invited to a separate study on Prolific allowing you to enter a new completion code every week. This guarantees that you get paid for each contribution you make to the study. After you finished the first study unit, you will be invited to ALL upcoming study units (2-9) at once to make sure you can start the test in the app once it is available and enter the completion code in the respective Prolific study.</p>
                <p class="mb-3">After completion of a test and the related questionnaire, you will get a completion code specifically for this study unit displayed within the neotivTrials app. You need to enter this code in the respective Prolific study for this study unit to submit your data. Afterward, we check the generated data for general validity until you receive the payment.</p>
                <p class="mb-3">In case of questions toward the study, please send a message via the "Contact for Support" on the bottom of this Website.</p>
                <p class="mb-3"><b>Important: Please start this study on Prolific after you have performed the test in the neotivTrials app. You will be provided with a new Completion Code at the end of the study unit within the app. Please enter the new Completion Code on Prolific after finishing this upcoming study unit.</b></p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="When is my study contribution going to be paid?"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-3">The submission will be paid if the data fulfills the basic data validity criteria after our data submission check. We have up to 21 days to perform the data submission.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="I was invited on Prolific to the next study unit(s) but the neotivTrials app does not show my next test to be available"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-3">Once the neotivTrials app makes the next test available and you have the time to start (and complete) the test unit, then right after performing the whole test (part 1 and 2) in the app, start the Prolific study for the respective test unit and enter the completion code on Prolific right away. Your spot is reserved for your ID specifically and cannot run out.</p>
                <p class="mb-3"> You can leave the invitation(s) on Prolific open until you are ready to perform the test in the app and enter the completion code afterward.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel
            title="What if I enter the completion code from the neotivTrials app in the wrong Prolific study unit?"
          >
          <v-expansion-panel-text>
            <v-row no-gutters>
              <v-col cols="12" class="py-6">
                <p class="mb-3">If you, for example, entered the completion code from the app of the study unit 2 in the Prolific website in study unit 3, we will register in the data check for your 3rd study unit that a wrong code was entered. This will not be a problem for the 3rd study unit since we will do a data check anyway.</p>
                <p class="mb-3">It is still important that you enter the code on the Prolific website for study unit 2 so that we receive the notice of you having finished that study unit. Again, it is not critical if the correct code was entered as long as you end your participation for that study unit.</p>
                <p class="mb-3">In order for the data submissions to run as smoothly as possible we kindly ask you to always carefully check to enter the correct completion codes for the corresponding study units.</p>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
          </v-expansion-panel>
      </v-expansion-panels>
      </v-container>
    </section>


    <!-- Contact form -->
    <section class="contact bg-primary">
      <v-container class="py-12">
        <v-container>
          <h3 class="text-white text-center">
            Contact for Support
          </h3>
          <p class="text-white mt-7">
            In case you have troubles downloading the app or accessing the study, you can contact us by using the contact form.
          </p>
        </v-container>
        <contact-form
          :formColor="'#57509C'"
          :formBtnColor="'#6DC1B9'"
          :newsletter="false"
        />
      </v-container>
    </section>

  </v-container>
</template>

<script>
import ContactForm from "@/components/Form";
import HeroComponent from "@/components/Hero";

export default {
  components: {
    ContactForm,
    HeroComponent
  },
  methods: {
    scrollToContact () {
      const element = document.querySelector('.contact')
      element.scrollIntoView({ behavior: 'smooth' })
    }
  },
  computed: {
    prolificVideoInstruction() {
      return require("@/assets/videos/prolific-video-instruction.mp4");
    },
    prolificStudyDesign() {
      return require("@/assets/images/prolific/prolific-study-design.png");
    },
    registerWithProlificEmail() {
      return require("@/assets/images/prolific/register-with-prolific-email.png");
    },
    prolificAccessCodeAndLink() {
      return require("@/assets/images/prolific/prolific-access-code-and-link.png");
    },
    setPassword() {
      return require("@/assets/images/prolific/set-password.png");
    },
    studiesRestrictedAcess() {
      return require("@/assets/images/prolific/studies-restricted-access.png");
    },
    login() {
      return require("@/assets/images/prolific/login.png");
    },
    qrTrialsApple() {
      return require("@/assets/images/prolific/qr_ntrials_apple_app_store_medium_size.svg");
    },
    qrTrialsGoogle() {
      return require("@/assets/images/prolific/qr_ntrials_google_play_store_medium_size.svg");
    },
    appleLogo () {
      return require("@/assets/images/prolific/apple-store-logo.png");
    },
    androidLogo () {
      return require("@/assets/images/prolific/google-play-logo.png");
    },
    isWithinAllowedWeek() {
      const currentDate = new Date();
      const allowedDate = new Date();
      allowedDate.setDate(allowedDate.getDate() + 7);
      return currentDate <= allowedDate;
    }
  }
};
</script>

<style scoped>
.pred {
  color: #B00020;
}

.violet {
  color: #57509c;
}

.v-expansion-panel-text {
  max-height: 500px;
  overflow-y: auto;
}

video {
    background-color: var(--v-videoBackgroundColor-base);
    border: 1px solid var(--v-videoBackgroundColor-base);
  }
</style>
