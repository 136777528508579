<!--
 Name: Team.vue
 Description: View to display the team page.
 Created by: Jana Schumann on 2019-11-07
 Last edited by: Jana Schumann on 2025-04-01
-->

<template>
  <v-container fluid class="pa-0 full-width">
    <!-- team -->
    <section id="all">
      <v-container class="mb-15">

        <h2 class="my-12">{{ $t("team.core_headline") }}</h2>
        <p class="text-justify mb-15">{{ $t("team.core_text") }}</p>

        <!-- core team -->
        <v-row>
          <v-col cols="12" sm="6" md="3" v-for="(member, index) in team" :key="index">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/${member.image}`)" aspect-ratio="0.75" cover>
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ member.name }}</b>
              <br />
              {{ member.title }}
            </p>
          </v-col>
        </v-row>

      </v-container>
      <v-divider />
    </section>

    <!-- science -->
    <section id="team-science">
      <v-container class="mb-15">
        <h2 class="my-12 text-center">{{ $t("team.science_headline") }}</h2>
        <p class="text-center mb-15">{{ $t("team.science_text") }}</p>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3" class="pr-5">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/science/wolfgang-hoffmann.jpg`)" aspect-ratio="1">
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ $t("team.science_name_hoffmann") }}</b>
              <br />
              {{ $t("team.science_institute_hoffmann") }}
              <br />
              {{ $t("team.science_research_hoffmann") }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="pr-5">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/science/robert-howard.jpg`)" aspect-ratio="1">
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ $t("team.science_name_howard") }}</b>
              <br />
              {{ $t("team.science_institute_howard") }}
              <br />
              {{ $t("team.science_research_howard") }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="pr-5">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/science/william-jagust.jpg`)" aspect-ratio="1">
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ $t("team.science_name_jagust") }}</b>
              <br />
              {{ $t("team.science_institute_jagust") }}
              <br />
              {{ $t("team.science_research_jagust") }}
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="6" md="3" class="pr-5">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/science/frank-jessen.jpg`)" aspect-ratio="1">
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ $t("team.science_name_jessen") }}</b>
              <br />
              {{ $t("team.science_institute_jessen") }}
              <br />
              {{ $t("team.science_research_jessen") }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="pr-5">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/science/pierluigi-nicotera.jpg`)" aspect-ratio="1">
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ $t("team.science_name_nicotera") }}</b>
              <br />
              {{ $t("team.science_institute_nicotera") }}
              <br />
              {{ $t("team.science_research_nicotera") }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="pr-5">
            <v-img class="bg-grey-lighten-2" :src="require(`@/assets/images/team/science/lars-nyberg.jpg`)" aspect-ratio="1">
            </v-img>
            <p class="mt-3 text-center">
              <b>{{ $t("team.science_name_nyberg") }}</b>
              <br />
              {{ $t("team.science_institute_nyberg") }}
              <br />
              {{ $t("team.science_research_nyberg") }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
import _ from 'lodash'

export default {
  data() {
    return {
      team: [
        {
          image: 'core/chris-rehse.jpg',
          name: 'Dr. Chris Rehse',
          title: 'Founder and Chief Executive Officer'
        },
        {
          image: 'core/julian-haupenthal.jpg',
          name: 'Julian Haupenthal',
          title: 'Founder and Chief Product Officer'
        },
        {
          image: 'core/emrah-duezel.jpg',
          name: 'Prof. Dr. med. Emrah Düzel',
          title: 'Founder and Chief Medical Officer'
        },
        {
          image: 'core/david-berron.jpg',
          name: 'Dr. David Berron',
          title: 'Founder and Chief Science Officer'
        },
        {
          image: 'core/jana-schumann.jpg',
          name: 'Jana Schumann',
          title: 'Chief Technology Officer'
        },
        {
          image: 'core/bernd-neutschel.jpg',
          name: 'Dr. Bernd Neutschel',
          title: 'Chief Operations Officer'
        },
        {
          image: 'core/mileen-mollenhauer.jpg',
          name: 'Mileen Mollenhauer',
          title: 'Lead UX/UI Designer'
        },
        {
          image: 'core/susanne-wiesner.jpg',
          name: 'Susanne Wiesner',
          title: 'Senior UX Designer'
        },
        {
          image: 'core/safa-bougouffa.jpg',
          name: 'Safa Bougouffa',
          title: 'Team Lead Quality Assurance'
        },
        {
          image: 'core/martin-leuckert.jpg',
          name: 'Martin Leuckert',
          title: 'Software Architect'
        },
        {
          image: 'core/pascal-robert.jpg',
          name: 'Pascal Robert',
          title: 'Team Lead App Development'
        },
        {
          image: 'core/florian-boesenkopf.jpg',
          name: 'Florian Bösenkopf',
          title: 'Senior Manager Finance & Investor Relations'
        },
        {
          image: 'core/florian-heinzmann.jpg',
          name: 'Dr. Florian Heinzmann',
          title: 'Regulatory Affairs Manager'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Julia Günther',
          title: 'User Interface Designer'
        },
        {
          image: 'core/hashim-naveed.jpg',
          name: 'Muhammad Hashim Naveed',
          title: 'Junior Data Scientist'
        },
        {
          image: 'core/carolin-cienkowski.jpg',
          name: 'Carolin Cienkowski',
          title: 'HR Generalist'
        },
        {
          image: 'core/aero.jpg',
          name: 'Aero',
          title: 'Feel Good Manager'
        },
        {
          image: 'avatar_m_logo.svg',
          name: 'Gordon Lenz',
          title: 'Linux System Administrator'
        },
        {
          image: 'core/alexander-may.jpg',
          name: 'Alexander May',
          title: 'Team Lead IS & IT'
        },
        {
          image: 'core/paula-saering.jpg',
          name: 'Dr. Paula Säring',
          title: 'Senior Project Manager Study Coordination'
        },
        {
          image: 'core/ina-hempen.jpg',
          name: 'Ina Hempen',
          title: 'Junior Scientist'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Ida Voigt',
          title: 'Working Student Customer Support'
        },
        {
          image: 'core/hannes-voßfeldt.jpg',
          name: 'Dr. Hannes Voßfeldt',
          title: 'Project Manager Digital Healthcare'
        },
        {
          image: 'avatar_m_logo.svg',
          name: 'Ricardo Alarcon',
          title: 'Linux System Administrator'
        },
        {
          image: 'core/xandra-volkmann.jpg',
          name: 'Dr. Xandra Volkmann',
          title: 'Team Lead QM & Regulatory, PRRC'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Dr. Nadine Diersch',
          title: 'Team Lead Science'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Sandra Linn',
          title: 'Junior Finance Manager'
        },
        {
          image: 'core/valentin-kauth.jpg',
          name: 'Valentin Kauth',
          title: 'Product Manager'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Dr. Lena Krzyzak',
          title: 'Chief Business Officer'
        },
        {
          image: 'core/lokeswari-madhusudhana.jpg',
          name: 'Lokeswari Madhusudhana',
          title: 'Senior Web Developer'
        },
        {
          image: 'core/carmine-toriello.jpg',
          name: 'Carmine Toriello',
          title: 'App Developer'
        },
        {
          image: 'core/roman-ustinov.jpg',
          name: 'Roman Ustinov',
          title: 'Quality Assurance Engineer'
        },
        {
          image: 'core/maike-kuehn.jpg',
          name: 'Maike Kühn',
          title: 'Team Lead People & Culture'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Natascha Dreesmann',
          title: 'Working Student Study Coordination'
        },
        {
          image: 'core/eva-ackermann.jpg',
          name: 'Eva Ackermann',
          title: 'Market Access Manager'
        },
        {
          image: 'avatar_m_logo.svg',
          name: 'Hafiz Muhammad Hassan',
          title: 'IT Administrator'
        },
        {
          image: 'core/sascha-harant.jpg',
          name: 'Sascha Harant',
          title: 'Working Student IT/IS'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Laura-Soraya Schulz',
          title: 'Office Manager'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Cäcilie Lotz',
          title: 'Marketing Manager'
        },
        {
          image: 'avatar_m_logo.svg',
          name: 'Tomer Almog',
          title: 'Backend Developer'
        },
        {
          image: 'avatar_w_logo.svg',
          name: 'Rakshitha Ramakrishna',
          title: 'Working Student QM & Regulatory Affairs'
        },
        {
          image: 'avatar_m_logo.svg',
          name: 'Markus Hartmann',
          title: 'Customer Care Manager'
        }
      ]
    }
  },
  created () {
    this.team = _.shuffle(this.team);
  }
};
</script>
